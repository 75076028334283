<template>
    <v-container>
        <v-card outlined width="430" shaped elevation="5" class="mt-5 mx-auto">
            <v-img
                aspect-ratio="4.3"
                lazy-src="@/assets/img/login.jpg"
                src="@/assets/img/login.jpg"
            ></v-img>
            <v-divider></v-divider>
            <v-card-title
                class="justify-center pa-1 ma-0 text-h5 font-weight-bold black--text"
            >
                Rhea Admin Panel
            </v-card-title>
            <v-card-subtitle
                class="pa-0 ma-0 text-h6 font-weight-bold black--text"
            >
                <center>Log In</center>
            </v-card-subtitle>

            <v-form ref="form" v-model="valid">
                <v-card-text>
                    <v-text-field
                        label="Email"
                        v-model="user.email"
                        dense
                        outlined
                        prepend-inner-icon="mdi-account-circle"
                        :rules="[rules.required, rules.email]"
                        autocomplete="off"
                    >
                    </v-text-field>
                    <v-text-field
                        label="Password"
                        v-model="user.passw"
                        dense
                        outlined
                        prepend-inner-icon="mdi-lock"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPasswordClick"
                        :rules="[rules.required]"
                        @keyup.enter="user.passw && login()"
                    >
                    </v-text-field>
                    <v-alert v-model="alert" dense outlined type="error">
                        {{ errorMessage }}
                    </v-alert>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn
                        color="#61B15A"
                        class="white--text"
                        @click="login"
                        :disabled="!valid"
                        :loading="loading"
                        style="text-transform: unset !important"
                    >
                        Log In
                    </v-btn>
                    <v-btn
                        color="secondary"
                        @click="reset"
                        style="text-transform: unset !important"
                    >
                        Reset
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>

        <v-dialog
            v-model="alertGps"
            persistent
            class="rounded-lg"
            max-width="450"
        >
            <v-card class="rounded-lg pb-3">
                <v-list-item class="pt-4 pb-2">
                    <v-list-item-avatar tile>
                        <v-icon color="blue">mdi-map-marker-radius</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content
                        class="pa-0 text-subtitle-2 black--text"
                    >
                        Aplikasi ini membutuhkan akses lokasi anda. Setujui
                        penggunaan GPS untuk mulai menggunakan aplikasi ini.
                    </v-list-item-content>
                </v-list-item>
                <v-card-actions>
                    <v-btn
                        depressed
                        block
                        dark
                        color="#EB4646"
                        style="text-transform: unset !important"
                        @click="refreshPage"
                    >
                        Mengerti
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "Login",

    metaInfo: {
        title: "Rhea Admin",
        titleTemplate: "%s - Login",
        htmlAttrs: {
            lang: "id",
            amp: true,
        },
    },

    data: () => ({
        position: null,
        valid: false,
        alert: false,
        alertGps: false,
        showPassword: false,
        loading: false,
        user: {
            email: "",
            passw: "",
            lat: "",
            long: "",
        },
        rules: {
            required: (value) => !!value || "Tidak boleh kosong !!",
            email: (value) => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || "e-mail tidak valid !!";
            },
        },
        errorMessage: "",
    }),

    methods: {
        ...mapActions({
            logIn: "auth/logIn",
        }),

        activateNavigator() {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.position = `${position.coords.latitude},${position.coords.longitude}`;
                    this.user.lat = position.coords.latitude;
                    this.user.long = position.coords.longitude;
                    if (this.position === null) {
                        this.alertGps = true;
                    }
                },
                (error) => {
                    if (this.position === null) {
                        this.alertGps = true;
                    }
                    console.log(error.message);
                }
            );
        },
        showPasswordClick() {
            this.showPassword = !this.showPassword;
        },
        async login() {
            this.loading = true;
            this.alert = false;

            const data = JSON.stringify(this.user);
            this.logIn(data)
                .then(() => {
                    this.loading = false;
                    this.$router.replace({
                        name: "Dashboard",
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        this.setError(error.response.data.errMessage);
                        console.clear();
                    }
                });
        },
        setError(value) {
            this.errorMessage = value;
            this.alert = true;
            this.loading = false;
        },
        reset() {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.alert = false;
        },
        refreshPage() {
            this.$router.go(this.$router.currentRoute);
            window.scrollTo({
                top: 0,
                left: 0,
            });
        },
    },
    created() {
        this.activateNavigator();
    },
};
</script>
